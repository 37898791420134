import * as React from "react";

function Text() {
  return (
    <article className="text-lg leading-9 text-black max-w-7xl px-2 flex flex-col gap-4 md:gap-8">
      <p>
        ESG (Environmental, Social, and Governance) factors are increasingly recognised as critical contributors to business success. By prioritising ESG considerations in your business, you can enhance long-term sustainability and resilience while also generating positive outcomes for stakeholders.
      </p>
      <p>
        Environmental initiatives such as reducing carbon emissions, minimising waste, and conserving resources not only mitigate risks associated with climate change but also lead to cost savings and operational efficiencies.
      </p>
      <p>
        Socially collaborative steps, including fostering diversity and inclusion, incorporating fair labor practices, and supporting community engagement, individually and collectively contribute to a positive corporate culture while enhancing brand reputation, and attracting top talent.
      </p>
      <p>
        Governance factors such as transparent reporting, ethical leadership, and effective risk management frameworks instill trust among investors, leading to improved access to capital and long-term shareholder value.
      </p>
      <p>
        In summary, integrating ESG considerations into your business practices is not only the right thing to do ethically but also delivers tangible benefits, driving innovation, competitiveness, and sustainable growth. We can be your sustainability partner for integrating ESG successfully into your business strategy.
      </p>
    </article>
  );
}

export default Text;