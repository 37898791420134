import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100% !important",
    width: "100% !important",
    //   right: "100px",
    background: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100vw !important",
    //   height: "100vh !important",
    //   right: "0px",
    //   background: "linear-gradient(135.74deg, #09101B 4.03%, #112035 100%)",
    //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    // },
  },
}));

function ImageCard({ src, alt, title,link }) {
  return (
    <div className="overflow-hidden relative flex-col grow justify-center text-xl font-black text-white uppercase aspect-[1.32]  max-md:px-5 max-md:mt-3">
      <img
        loading="lazy"
        src={src}
        alt={alt}
        className="object-cover absolute inset-0 -z-10 size-full"
      />
      <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center ">
        {title}
      </p>
    </div>
  );
}

function ImageCardSmall({ src, alt, title,link }) {
  return (
    <div className="overflow-hidden relative flex-col grow justify-center  text-xl font-black text-white uppercase aspect-[1.11]  max-md:px-5 max-md:mt-4">
      <img
        loading="lazy"
        src={src}
        alt={alt}
        className="object-cover absolute inset-0 size-full -z-10"
      />
      <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        {title}
      </p>
    </div>
  );
}

function Sidebar(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const largeCards = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/80b488273d8019de733c38b90e1068be34859fcff081da904496f568cf7deb65?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      alt: "NIRantara journey image",
      title: "NIRantara journey",
      link: "/journey",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/a791d1201d8a4e629c194ee54a8ea4777eb72481a789add94e497d7a555d1c05?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      alt: "Products image",
      title: "PRODUCTS",
      link: "/products",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/a0c60aac64023a6ae02394229dd8bf2446525cda35ee4e3a1aed61484bb2b8e1?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      alt: "Membership and partnership image",
      title: "",
      link: "/membership-and-partnership",
    },
  ];

  const smallCards = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d9fc42e49c1c71f4548e75b9a0b484a56cc38b97399610ac51985342e1e191f7?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      alt: "Resources image",
      title: "RESOURCES",
      link: "/resources",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4cd93d54bc072ed949ba19446b7797b8d3042da8aaf07cf1706609cedaed41b2?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      alt: "Careers image",
      title: "CAREERS",
      link: "/career",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b30e2944e400fd49a512335df113dc63f9cba177b01c03dfcb4c3a33fcc874b4?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      alt: "Our team image",
      title: "Our team",
      link: "/our-team",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/bb8fb637c483b06ac0317f655bf449dbf5dcdf389d084b7a009601b512f98957?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      alt: "Contact us image",
      title: "CONTACT US",
      link: "/contact-us",
    },
  ];

  return (
    <Drawer
      anchor={"top"}
      open={props.open}
      onClose={props.onClose}
      // className={styles.modal}
      PaperProps={{
        sx: {
          width: "100%",
          height: "fit-content !important",
        },
      }}
      classes={{ paper: classes.paper }}
      style={{ height: "80vh !important" }}
    >
      <div className="flex helvetica flex-col items-center px-16 pt-12 pb-6 bg-white backdrop-blur-[53.5px] max-md:px-5">
        <div className="flex flex-col w-full max-w-[981px] max-md:max-w-full">
          <section className="max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              {largeCards.map((card, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (card?.link) {
                      navigate(card.link);
                      props.onClose();
                    }
                  }}
                  className={`flex flex-col w-[33%] max-md:ml-0 cursor-pointer max-md:w-full ${
                    index > 0 ? "ml-5" : ""
                  }`}
                >
                  <ImageCard src={card.src} alt={card.alt} title={card.title} />
                </div>
              ))}
            </div>
          </section>
          <section className="mt-4 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              {smallCards.map((card, index) => (
                <div
                  key={index}
                  className={`flex cursor-pointer flex-col w-3/12 max-md:ml-0 max-md:w-full ${
                    index > 0 ? "ml-5" : ""
                  }`}
                  onClick={() => {
                    if (card?.link) {
                      navigate(card.link);
                      props.onClose();
                    }
                  }}
                >
                  <ImageCardSmall
                    src={card.src}
                    alt={card.alt}
                    title={card.title}
                  />
                </div>
              ))}
            </div>
          </section>
          <div
            onClick={props.onClose}
            className="flex gap-2.5 justify-center self-center mt-4 text-lg font-medium leading-7 text-black whitespace-nowrap cursor-pointer "
          >
            <button>CLOSE</button>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/74b7437eb3e3dc5ae385f5872aa919c447e6e3e453177bd0e41600690e08d524?apiKey=b3217365a35d45c196e47ed20ac8b87c&"
              alt="Close icon"
              className="shrink-0 my-auto w-6 aspect-square"
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Sidebar;
