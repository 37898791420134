import * as React from "react";
import { useNavigate } from "react-router";

function MenuIcon() {
  return (
    <div className="flex flex-col items-end self-end mr-20 text-lg font-semibold leading-5 text-right whitespace-nowrap w-[97px] max-md:mr-2.5">
      <div className="shrink-0 h-0.5 bg-white shadow-sm w-[22px]" />
      <div className="flex gap-3 self-stretch px-0.5">
        <div>MENU</div>
        <div className="shrink-0 my-auto h-0.5 bg-white shadow-sm w-[15px]" />
      </div>
      <div className="shrink-0 h-0.5 bg-white shadow-sm w-[30px]" />
    </div>
  );
}

function DiscoverMoreButton() {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate('/esg')} className="justify-center flex gap-4 items-center px-6 py-3 mt-8 max-w-full text-base text-center text-[#45A6D2]  bg-white w-[212px] max-md:px-5">

      <span className="">Discover More</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="37" height="16" viewBox="0 0 37 16" fill="none">
        <path d="M36.7071 8.70711C37.0976 8.31659 37.0976 7.68342 36.7071 7.2929L30.3431 0.928935C29.9526 0.53841 29.3195 0.53841 28.9289 0.928935C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM-8.74228e-08 9L36 9L36 7L8.74228e-08 7L-8.74228e-08 9Z" fill="#45A6D2" />
      </svg>
    </button>
  );
}

function ESGRight() {
  return (
    <section
      className="flex flex-col px-8  text-white w-full max-md:px-5 h-full "
      style={{
        background: " linear-gradient(36deg, #369ECB 36.93%, rgba(81, 186, 234, 0.62) 98.7%)",
      }}
    >
      <h1 className="mt-24 text-2xl font-black uppercase max-md:mt-10 max-md:max-w-full">
        ESG
      </h1>
      <p className="mt-7 text-xl leading-9 max-md:max-w-full">
        Discover how we're integrating ESG principles into our business strategy, driving positive impact
        on the environment, society, and governance practices. Join us in advancing sustainability and
        ethical standards in business for a brighter future.
      </p>
      <DiscoverMoreButton />
    </section>
  );
}

export default ESGRight;
