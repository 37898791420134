import React, { useState, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";

// Assuming these component imports are correct
import SustainabilityLeft from "./Sustainability/SustainabilityLeft";
import SustainabilityRight from "./Sustainability/SustainabilityRight";
import ESGLeft from "./ESG/ESGLeft";
import ESGRight from "./ESG/ESGRight";
import ApproachLeft from "./Approach/ApproachLeft";
import ApproachRight from "./Approach/ApproachRight";
import ClimateLeft from "./Climate/ClimateLeft";
import ClimateRight from "./Climate/ClimateRight";
import { useNavigate } from "react-router";
import searchJson from './nirantara';
import { Link } from "react-router-dom";

// Your components structured for left and right animations


function SearchBar() {
  const navigate = useNavigate();
  const [q, setQ] = useState("");
  const [error, setError] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  // Function to handle scrolling based on input focus
  function handleScroll(val) {
    const scrollPosition = isInputFocused ? 0 : val;
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const results = searchHandler(q);
    console.log("results", results)
    if (results.length > 0) {
      setError(false);
      setSearchResults(results);
      setShowDropdown(true);
    } else {
      setError(true);
      setSearchResults([]);
      setShowDropdown(false);
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setQ(query);
    // handleInputFocus()
    if (query.trim() === "") {
      setShowDropdown(false);
    } else {
      const results = searchHandler(query);
      setSearchResults(results);
      setShowDropdown(true);
    }
  };

  const handleItemClick = (route, sentence) => {
    navigate(`${route}?search=${sentence}`);
    setShowDropdown(false);
    setQ("");
  };

  const highlightSearchQuery = (sentence, query) => {
    const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedQuery})`, 'gi');
    return sentence.replace(regex, '<span class="highlight">$1</span>');
  };

  function handleInputFocus() {

    setIsInputFocused(true);
    handleScroll(200);
  }

const BlankInput =()=>{
  setShowDropdown(false)
  document.getElementById('search').value = '';
  setQ("")
}

  return (
    <div>
      <form onSubmit={handleSearchSubmit} className={`flex w-full  gap-1.5 items-center px-6 py-2 mt-8 text-lg tracking-tight leading-6 rounded backdrop-blur-[18.5px] bg-zinc-100 bg-opacity-20 max-md:flex-wrap max-md:px-5 ${error ? "border-2 border-red-500" : ""} `}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/8fb14f43ab36ca09729453f8131c54ce2b284b2fa3ed65de54c8b3ed2507abc2?apiKey=b3217365a35d45c196e47ed20ac8b87c&"
          alt=""
          className="shrink-0 self-stretch my-auto w-4 aspect-square fill-white"
        />
        <label htmlFor="search" className="sr-only helvetica">
          What are you looking for?
        </label>
        <input
          type="text"
          id="search"
          placeholder="What are you looking for?"
          aria-label="Search"
          style={{width:'305px'}}
          className={` flex-1 py-2 self-stretch max-md:max-w-full bg-transparent outline-none text-white placeholder-white  `}
          onChange={handleInputChange}
          onClick={() => handleScroll(window.innerWidth < 768 ? 0 : 200)}
        />
        {showDropdown && <span onClick={BlankInput}  className="cursor-pointer" >&times;</span>}
        <button type="submit" aria-label="Search">
        </button>
      </form>
      {showDropdown && (
        <div className="absolute z-10 search_result mt-1 bg-transparent outline-none divide-y  rounded-md shadow-lg origin-top-left ">
          <div

            className="px-4 py-3 flex gap-4   text-sm _g_search rounded mb-1 self-stretch max-md:max-w-full backdrop-blur-[18.5px] bg-zinc-100 bg-opacity-20 cursor-pointer"

          >
            <span className="highlight shadow-lg">{searchResults?.reduce((totalSentences, result) => totalSentences + result.sentences.length, 0)}</span> Result Found

          </div>

          {searchResults.map((result, index) => (
            <div key={index} className="">

              {result.sentences.map((sentence, sentenceIndex) => (
                <div
                  key={sentenceIndex}
                  className="px-4 py-3 flex gap-4  text-sm _g_search rounded mb-1 self-stretch  backdrop-blur-[18.5px] bg-zinc-100 bg-opacity-20 cursor-pointer"
                  onClick={() => handleItemClick(result.route, sentence)}
                >
                  <span className="text-white ">{result.page} - </span>
                  {/* <p className="text-white">{sentence}</p> */}
                  <p className="text-white" dangerouslySetInnerHTML={{ __html: highlightSearchQuery(sentence, q) }} />

                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}


const searchHandler = (q) => {
  const queryWords = q.toLowerCase().split(/\s+/);

  const searchResults = searchJson.filter((item) => {
    return item.sentences.some((sentence) => {
      return queryWords.every(keyword => sentence.toLowerCase().includes(keyword));
    });
  });

  // Collect all matching sentences for each route
  const resultSentences = searchResults.map((result) => {
    const matchingSentences = result.sentences.filter((sentence) => {
      return queryWords.some(keyword => sentence.toLowerCase().includes(keyword));
    });
    return { route: result.route, page: result.page, sentences: matchingSentences };
  });

  return resultSentences;
};





function Landing() {
  const [index, setIndex] = useState(0); // To track the current component index
  const [show, setShow] = useState(false); // To control the visibility and trigger animations

  const [showBack, setShowBack] = useState(false);

  const [backToTop, setBackToTop] = useState(false);



  // React Spring animation hook for transitioning components
  const { y } = useSpring({
    from: { y: 0 },
    to: { y: show ? 100 : -100 },
    reset: true,
    reverse: show,
    config: { mass: 1, tension: 180, friction: 60 },
  });

  useEffect(() => {
    if (index === components.length - 1) {
      setShowBack(true);
    } else {
      setShowBack(false);
    }
  }, [index, setIndex]);

  // Handler for "Next" button click, advancing to the next set of components
  const handleNextClick = () => {
    setIndex((prevIndex) => (prevIndex + 1) % components.length); // Advance index safely
  };

  console.log("show", show);

  // Handler for "Previous" button click, returning to the previous set of components
  const handlePrevClick = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? components.length - 1 : prevIndex - 1
    ); // Go back an index safely
  };

  useEffect(() => {
    if (backToTop) {
      setShow(false);
      setIndex(0);
      setTimeout(() => {
        setBackToTop(false);
      }, 100);
    }
  }, [backToTop]);


  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function countdownWithDelay() {
    for (let i = components.length - 1; i >= 0; i--) {
      setIndex(i);
      await sleep(300);
    }
    setShow(false);
    setBackToTop(false);
  }

  const components = [
    {
      left: <SustainabilityLeft setIndex={setIndex} />,
      right: <SustainabilityRight />,
    },
    {
      left: <ESGLeft setIndex={setIndex} />,
      right: <ESGRight />,
    },
    {
      left: <ApproachLeft setIndex={setIndex} />,
      right: <ApproachRight />,
    },
    {
      left: <ClimateLeft setIndex={setIndex} />,
      right: <ClimateRight />,
    },
  ];

  return (
    <div className="flex flex-col justify-center text-white bg-white">
      {!show ? (
        <div className="flex flex-col justify-center text-white bg-white">
          <div className="flex flex-col justify-center text-white bg-white">
            <div className="flex flex-col  justify-center w-full bg-white max-md:max-w-full">
              <section className="flex   overflow-hidden relative flex-col justify-center items-center px-16 py-5 w-full min-h-[calc(100vh)] max-md:px-5 max-md:max-w-full">
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="absolute z-0 w-auto min-h-[490px] min-w-full  max-w-none"
                >
                  <source src="/assets/videos/video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="flex z-10 relative flex-col items-center w-full max-w-[1200px] max-md:max-w-full">
                  <h1 className="mt-16 text-center text-7xl font-black text-white uppercase leading-[80.25px] max-md:mt-10 max-md:max-w-full max-md:text-2xl helvetica ">
                    SUSTAINING
                    <br />
                    SUSTAINABILITY
                  </h1>
                  <SearchBar />
                  <div
                    className="mt-16 md:block hidden text-sm uppercase max-md:mt-10 helvetica cursor-pointer "
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Read OUR STORY
                  </div>
                  <div className="shrink-0 w-px md:block hidden bg-white border border-white border-solid h-[51px]" />
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full hidden md:flex flex-col gap-8 items-center relative max-h-[100vh] ">
          <div className="w-full grid grid-cols-12 h-full overflow-hidden ">
            <animated.div
              className="col-span-7"
              style={{
                transform: y.to((y) => `translateY(${y}%)`),
              }}
            >
              {components[index]?.left}
            </animated.div>
            <animated.div
              className="col-span-5"
              style={{
                transform: y.to((y) => `translateY(-${y}%)`),
              }}
            >
              {components[index]?.right}
            </animated.div>
          </div>
          {!showBack ? (
            <div className="flex absolute bottom-4  flex-col gap-4 max-w-[35px]">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/16eb2a54844637cd02365fbe34a9dfea47401cffc8661f106855c08981fb87aa?apiKey=8d89067347d641b99b038352d46b6e1d&"
                alt=""
                className="w-full aspect-square cursor-pointer "
                onClick={handlePrevClick}
              />

              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3abbcdb32f34b073360fe9aab7336032b2a78b545003eceb0a9f5c340fcaa8ca?apiKey=8d89067347d641b99b038352d46b6e1d&"
                alt=""
                className="w-full aspect-square cursor-pointer "
                onClick={handleNextClick}
              />
            </div>
          ) : (
            <div className="w-fit flex flex-col items-center absolute bottom-4">
              <div
                className="mt-52 text-sm uppercase max-md:mt-10 text-white helvetica cursor-pointer "
                onClick={() => {
                  setBackToTop(true);
                }}
              >
                Home
              </div>
              <div className="shrink-0 w-px bg-white border border-white border-solid h-[51px]" />
            </div>
          )}
        </div>
      )}
      <div className="w-full md:hidden flex flex-col montserrat ">
        <section className="overflow-hidden relative flex-col justify-center items-start px-3 pt-32 pb-20 text-3xl font-black text-white uppercase aspect-[1.27] w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/03772a5b6fe4810837da5716da48279af40d5ffd608fa8df9c563e8e5c67ef1e?apiKey=8d89067347d641b99b038352d46b6e1d&"
            className="object-cover absolute inset-0 size-full"
            alt="Sustainability background"
          />
          {/* <h2>SUSTAINABILITY</h2> */}
        </section>
        <section
          className="flex flex-col justify-center text-white bg-white w-full"
          style={{
            background: "linear-gradient(36deg, #F08110 36.93%, #F3B677 98.7%)",
          }}
        >
          <div className="flex flex-col px-5 py-9 w-full">
            <h2 className="text-lg font-black uppercase">
              SUSTAINING SUSTAINABILITY
            </h2>
            <p className="mt-3 text-xs leading-5">
              Explore how we're intertwining profitability with responsibility,
              fostering a harmonious relationship between commerce,
              environmental responsibility and social equity. Together, let us
              lay the foundation for a more sustainable and prosperous present
              while creating positive changes for the generations to come.
            </p>
            <button className=" flex gap-4 justify-center items-center px-6 lg:w-2/3 py-3 mt-5 text-base text-center text-orange-500  bg-white">
              <Link to="/sustainability">
                <span className="capitalize">Discover More</span>
              </Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="16"
                viewBox="0 0 37 16"
                fill="none"
              >
                <path
                  d="M36.7071 8.70711C37.0976 8.31659 37.0976 7.68342 36.7071 7.2929L30.3431 0.928935C29.9526 0.53841 29.3195 0.53841 28.9289 0.928935C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM-8.74228e-08 9L36 9L36 7L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="#F1891E"
                />
              </svg>
            </button>
          </div>
        </section>
        <section className="overflow-hidden relative flex-col justify-center items-start px-5 pt-32 pb-20 text-3xl font-black tracking-wider text-white uppercase whitespace-nowrap aspect-[1.27] leading-[60px] w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/300dead1f02def468b0ebea30035b243e200a8e52f97b434f8ad3961f2653b20?apiKey=8d89067347d641b99b038352d46b6e1d&"
            className="object-cover absolute inset-0 size-full"
            alt=""
          />
          <h1>ESG</h1>
        </section>
        <section
          className="flex flex-col justify-center text-white bg-white w-full"
          style={{
            background:
              "linear-gradient(36deg, #369ECB 36.93%, rgba(81, 186, 234, 0.62) 98.7%)",
          }}
        >
          <div className="flex flex-col px-5 py-9 w-full">
            <h2 className="text-lg font-black uppercase">ESG</h2>
            <p className="mt-3 text-xs leading-5">
              Explore how we're intertwining profitability with responsibility,
              fostering a harmonious relationship between commerce,
              environmental responsibility and social equity. Together, let us
              lay the foundation for a more sustainable and prosperous present
              while creating positive changes for the generations to come.
            </p>
            <button className=" flex gap-4 justify-center items-center px-6 lg:w-2/3 py-3 mt-5 text-base text-center text-[#45A6D2]  bg-white">
            <Link to="/esg">
            <span className="capitalize">Discover More</span>
            </Link>
             
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="16"
                viewBox="0 0 37 16"
                fill="none"
              >
                <path
                  d="M36.7071 8.70711C37.0976 8.31659 37.0976 7.68342 36.7071 7.2929L30.3431 0.928935C29.9526 0.53841 29.3195 0.53841 28.9289 0.928935C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM-8.74228e-08 9L36 9L36 7L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="#45A6D2"
                />
              </svg>
            </button>
          </div>
        </section>
        <section className="overflow-hidden relative flex-col justify-center items-start px-5 pt-28 pb-20 text-3xl font-black tracking-wider leading-10 text-white uppercase aspect-[1.27] w-full ">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/873167e8819f977264b7fc8d13484804e51411d79a9abf9b2c665543c73f7b56?apiKey=8d89067347d641b99b038352d46b6e1d&"
            className="object-cover absolute inset-0 size-full"
            alt=""
          />
        </section>
        <section
          className="flex flex-col justify-center text-white bg-white w-full"
          style={{
            background:
              "linear-gradient(36deg, #668D3D 36.93%, rgba(120, 176, 67, 0.57) 98.7%)",
          }}
        >
          <div className="flex flex-col px-5 py-9 w-full">
            <h2 className="text-lg font-black uppercase">Integrated Approach</h2>
            <p className="mt-3 text-xs leading-5">
              Explore how we're intertwining profitability with responsibility,
              fostering a harmonious relationship between commerce,
              environmental responsibility and social equity. Together, let us
              lay the foundation for a more sustainable and prosperous present
              while creating positive changes for the generations to come.
            </p>
            <button className=" flex gap-4 justify-center items-center px-6 lg:w-2/3 py-3 mt-5 text-base text-center text-[#668D3D]  bg-white">
            <Link to="/integrated-approach">

            <span className="capitalize">Discover More</span>

            </Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="16"
                viewBox="0 0 37 16"
                fill="none"
              >
                <path
                  d="M36.7071 8.70711C37.0976 8.31659 37.0976 7.68342 36.7071 7.2929L30.3431 0.928935C29.9526 0.53841 29.3195 0.53841 28.9289 0.928935C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM-8.74228e-08 9L36 9L36 7L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="#668D3D"
                />
              </svg>
            </button>
          </div>
        </section>
        <section className="overflow-hidden relative flex-col justify-center items-start px-5 py-20 text-3xl font-black tracking-wider leading-10 text-white uppercase aspect-[1.27] w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/74a0a66742f298a169a4598cf63816c594b3618cd1eeca7f6d2d0bc6394047b5?apiKey=8d89067347d641b99b038352d46b6e1d&"
            className="object-cover absolute inset-0 size-full"
            alt="Climate and ESG"
          />
        </section>
        <section
          className="flex flex-col justify-center text-white bg-white w-full"
          style={{
            background:
              "linear-gradient(36deg, #915022 36.93%, #F3B677 98.7%)",
          }}
        >
          <div className="flex flex-col px-5 py-9 w-full">
            <h2 className="text-lg font-black uppercase"> Climate and  ESG</h2>
            <p className="mt-3 text-xs leading-5">
              Explore how we're intertwining profitability with responsibility,
              fostering a harmonious relationship between commerce,
              environmental responsibility and social equity. Together, let us
              lay the foundation for a more sustainable and prosperous present
              while creating positive changes for the generations to come.
            </p>
            <button className=" flex gap-4 justify-center items-center px-6 lg:w-2/3 py-3 mt-5 text-base text-center text-[#925123]  bg-white ">
            <Link to="/climate-and-esg">
            <span className="capitalize">Discover More</span>
            </Link>
              
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="16"
                viewBox="0 0 37 16"
                fill="none"
              >
                <path
                  d="M36.7071 8.70711C37.0976 8.31659 37.0976 7.68342 36.7071 7.2929L30.3431 0.928935C29.9526 0.53841 29.3195 0.53841 28.9289 0.928935C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM-8.74228e-08 9L36 9L36 7L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="#925123"
                />
              </svg>
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Landing;
