import * as React from "react";

const jobData = [
  {
    index: 0, title: "ESG Manager",
    description:
      "Nirantara Internship Program is designed to offer aspiring professionals a unique opportunity to immerse themselves in the world of Environmental, Social, and Governance (ESG) consulting. This program aims to bridge the gap between academic knowledge and real-world application, providing hands-on experience in sustainability initiatives and ESG integration strategies. Here's a brief overview of what the program offers.",
  },
  {
    index: 1,
    title: "Junior ESG Executive",
    description:
      "Nirantara Internship Program is designed to offer aspiring professionals a unique opportunity to immerse themselves in the world of Environmental, Social, and Governance (ESG) consulting. This program aims to bridge the gap between academic knowledge and real-world application, providing hands-on experience in sustainability initiatives and ESG integration strategies. Here's a brief overview of what the program offers.",
  },
  {
    index: 2,
    title: "Marketing Executive",
    description:
      "Nirantara Internship Program is designed to offer aspiring professionals a unique opportunity to immerse themselves in the world of Environmental, Social, and Governance (ESG) consulting. This program aims to bridge the gap between academic knowledge and real-world application, providing hands-on experience in sustainability initiatives and ESG integration strategies. Here's a brief overview of what the program offers.",
  },
  {
    index: 3,
    title: "Research Analyst",
    description:
      "Nirantara Internship Program is designed to offer aspiring professionals a unique opportunity to immerse themselves in the world of Environmental, Social, and Governance (ESG) consulting. This program aims to bridge the gap between academic knowledge and real-world application, providing hands-on experience in sustainability initiatives and ESG integration strategies. Here's a brief overview of what the program offers.",
  },
  {
    index: 4,
    title: "Business Development Executive (BDE)",
    description:
      "Nirantara is looking for motivated and talented Business Development Executives to join our  growing sales and marketing team.   The BDE will be the front line for the Nirantara Sales Team and the first person a prospect will  talk to.   A hunter persona, she/he will go out and hunt for new opportunities, qualifying prospects, and  accounts to confirm fit & size."
  },
  {
    index: 5,
    title: "Marketing Specialist",
    description:
      "If you are passionate about sustainability, have a strategic marketing mindset, and thrive in a dynamic startup environment, Nirantara is the place for you! We are looking for motivated and talented Marketing Specialists to join our growing BD and marketing team. The ideal candidate will have a strong background in marketing within the sustainability or environmental sector and a passion for driving positive change through impactful marketing strategies. We need someone who is passionate about Sustainability and dedicated to shaping the narrative around, storytelling to drive a positive impact.",
  },
];



const Description4 = [

  {
    title: "Responsibilities",
    content: [
      "Build a Database of Prospects",
      "Manage Cold Calling and Warm Calling",
      "Identify top leads and be the first point of contact with potential customers",
      "Create, maintain and update the sales pipeline",
      "Research sectors & companies in target markets to manage sales & spot opportunities",
      "Manage account mapping, buyer personas, identify the right people in target accounts and set up meetings for sales heads",
      "Qualify accounts via direct/indirect validation before adding to the pipeline"
    ]
  },
  {
    title: "What we are looking for",
    content: [
      "Master’s degree in marketing and a preferred bachelor’s degree in environmental science",
      "Personal interest in the ESG space and the future of a sustainable planet",
      "A minimum of 2 years work experience in a similar sales role",
      "Proven ability to meet and exceed set targets- measured monthly, quarterly, and annually",
      "Experience of gathering business relevant information and generating client interest through a combination of in-person client meetings, remote selling and tele-sales approach",
      "Prior experience with CRM applications- viz. Salesforce, Zoho, etc.",
      "Strong CRM discipline, the ability to accurately maintain and forecast 30-60-90 day pipeline",
      "Ability to hustle, work with ambiguity, and actively participate in problem-solving"
    ]
  },
  {
    title: "Competencies   ( Must Have)",
    content: [
      "Excellent written and verbal communication.",
      "Proficiency using Microsoft Office & CRM",
      "Prior experience of proactive/direct external client contact and exposure to a professional / fast paced business environment",
      "Ability to work under pressure and ensure on-time delivery",
      "A persuasive communicator, who can connect with founders, CEOs, of small & medium enterprises",
      "Exceptional interpersonal skills – ability to synthesize complex issues and communicate",
      "Excellent command over written and spoken English",
      "Responsible, reliable, and a keen interest in learning.",
      "Need to be adept at interacting, communicating, and partnering with industry/account team and cross domain technical specialists"

    ]
  },
  {
    title: "Good to have",
    content: [
      "Willingness to go beyond self to take on larger goals",
      "Dependable",
      "A persuasive communicator",
      "Good secondary research skills and ability to find information independently",
      "Working Knowledge of tools related CRM & Marketing",
      "Excellent presentation skills- making ppts as well as delivery",
    ]
  },
  {
    title: "Benefits",
    content: [
      "At the heart of Nirantara is a collaborative culture that values open communication, teamwork, and continuous learning.",
      "A friendly work environment with an ‘open door’ policy",
      "Ability to work directly with Senior Management to shape the company.",
"An opportunity for professional development and growth with a competitive salary"
    ]
  },

];

const Description5 = [
  {
    title: "Responsibilities",
    content: [
      "Develop & execute comprehensive marketing plans to promote our brand, services, and thought leadership in the ESG consulting & advisory space.",
      "Support implementation of the go-to–market strategy, value propositions and solution/product positioning in accordance with the marketing strategy",
      "Collaborate with internal teams and create compelling content, including white papers, case studies, blog posts, and social media content, campaign mailers to engage our target audience",
      "Conduct market research and competitive analysis to identify trends, customer needs, and competitive landscape, and utilize insights to refine marketing strategies.",
      "Identify key influencers such as SMEs, journalists, and consultants and develop good relationships to achieve high media share-of-voic",
      "Develop and execute data-driven, theme-based marketing campaigns that build brand awareness, generate leads, and drive sales",
      "Continuously measure the impact of marketing campaigns and optimize execution",
      "Support sales teams with Company Profiles, Buyer journeys, buyer personas",
      "Stay updated on sustainability best practices, regulations, and emerging technologies in sustainability and marketing, and apply knowledge to innovate and enhance our marketing strategies.",
      "Identify industry events, conferences, and trade shows to enhance brand visibility"
    ]
  },
  {
    title: "What we are looking for",
    content: [
      "BBA or MBA degree in Marketing and a preferred BSc in environmental science",
      "Personal interest in the ESG space and a curiosity about solutions for Sustainability and the future of a sustainable planet",
      "A minimum 4-year work experience in a similar role, with a strong understanding of marketing principles, strategies, and tactics",
      "Experience in digital marketing, content creation, and lead generation",
      "Excellent communication skills, both written and verbal, with the ability to create compelling content and presentations.",
      "Proficiency in marketing tools and platforms, such as Marketing Automation tools, CRM, Google Analytics, etc.",

    ]
  },
  {
    title: "Competencies  ( Must have )",
    content: [
      "Excellent written and verbal communication.",
      "Proficiency in MS office, Sales CRM and marketing automation tools",
      "Ability to work under pressure and ensure on-time delivery",
      "Exceptional interpersonal skills",
      "Responsible, reliable, and keen interest in learning.",
      "Good understanding of and experience in all elements of the marketing mix",
      "Analytical mindset with the ability to interpret data, generate insights, and make data-driven decisions to optimize marketing performance.",
      "Ability to work effectively in a fast-paced, collaborative environment, manage multiple projects simultaneously, and meet deadlines.",
    ]
  },
  {
    title: "Good to have",
    content: [
      "Willingness to go beyond self to take on larger goals",
      "A persuasive communicator",
      "Good secondary research skills and ability to find information independently",
      "Working Knowledge of tools related CRM & Marketing Automation tools",
      "Excellent presentation skills- making ppts as well as delivery",

    ]
  },
  {
    title: "Benefits",
    content: [
      "At the heart of Nirantara is a collaborative culture that values open communication, teamwork, and continuous learning.",
      "A friendly work environment with an ‘open door’ policy",
      "Ability to work directly with Senior Management to shape the company",
      "An opportunity for professional development and growth complemented with a competitive salar"
    ]
  },

];



function JobCard({ index, title, description, details }) {
  const [showDescription, setShowDescription] = React.useState(false);

  const handleToggleDescription = () => {

    setShowDescription(!showDescription);
  };

  if (showDescription) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }



  let jobDescription = index == 0 ? Description4 : index == 1 ? Description5 : [];


  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains('blur_popup')) {
      setShowDescription(false);
    }
  };

  const [activeIndex, setActiveIndex] = React.useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const isSectionActive = (index) => {
    return activeIndex === index;
  };

  const getArrowIcon = (index) => {
    return isSectionActive(index) ? '🢖' : '🢗';
  };

  const handleDownload = (index) => {

    const map = {
      0: 'busDev',
      1: 'MarketSpec'
    }

    const filePath = process.env.PUBLIC_URL + `/assets/${map[index]}.docx`;
    const link = document.createElement('a');
    link.href = filePath;
    link.download = `${map[index]}.docx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <article className="flex flex-col grow p-8 w-full font-semibold bg-white rounded-lg shadow-lg max-md:px-5 max-md:mt-5 max-md:max-w-full">
      <h2 className="lg:text-3xl text-black capitalize max-md:max-w-full">
        {title}
      </h2>
      {description && (
        <p className="mt-6 text-lg leading-9 font-normal text-black max-md:max-w-full hidden_scrollbar h-80">
          {description}
        </p>
      )}

      {details ? (
        <button
          onClick={handleToggleDescription}
          className="flex justify-center items-center gap-6 px-6 py-3   mt-6 max-w-full text-base text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-sm w-[168px] max-md:px-5"
        >
          {showDescription ? 'Close Description' : 'View Description'}
        </button>
      ) : (
        <a
          href="mailto:hr@nirantara.solutions"
          className="flex justify-center items-center gap-6 px-6 py-3 mt-6 max-w-full text-base text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-sm w-[148px] max-md:px-5"
        >
          <p>Apply</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="16"
            viewBox="0 0 37 16"
            fill="none"
          >
            <path
              d="M36.7071 8.70711C37.0976 8.31659 37.0976 7.68342 36.7071 7.2929L30.3431 0.928935C29.9526 0.53841 29.3195 0.53841 28.9289 0.928935C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM-8.74228e-08 9L36 9L36 7L8.74228e-08 7L-8.74228e-08 9Z"
              fill="white"
            />
          </svg>
        </a>
      )}

      {details && showDescription && (
        <div onClick={handleBackgroundClick}
          className="blur_popup fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 lg:min-w-[600px]">
          <div className="bg-white p-8 rounded-lg max-w-md lg:min-w-[700px]  h-96 lg:min-h-[500px]">
            <p className="cursor-pointer decoration-lime-600	" style={{ float: 'right' }} onClick={() => handleDownload(index)}>Download </p>
            {/* <p className="cursor-pointer decoration-lime-600	" style={{ float: 'right', transform: 'scale(1.8)' }} onClick={handleToggleDescription}>&times; </p> */}
            <h3 className="text-2xl font-semibold mb-4 text-lime-600">Job Description</h3>
            <hr className="mb-4" />
            {/* {jobDescription.map((section, index) => (
              <div key={index}>
                <h4 className="text-xl font-semibold mb-2">{section.title}</h4>
                {Array.isArray(section.content) ? (
                  <ul className="list-disc ml-6 mb-4">
                    {section.content.map((item, idx) => (
                      <li className="font-light" key={idx}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p className="font-light">{section.content}</p>
                )}
              </div>
            ))} */}
            <div className="h-80 hidden_scrollbar" >
              {jobDescription.map((section, index) => (
                <div key={index} className="border rounded">
                  <button
                    className="w-full text-left px-4 py-2 text-xl font-semibold focus:outline-none"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span>{section.title}</span>
                    <span style={{ color: isSectionActive(index) ? '#82be2c' : '' }} className="float-right ">{getArrowIcon(index)}</span>
                  </button>
                  <div className={isSectionActive(index) ? 'block' : 'hidden'}>
                    {Array.isArray(section.content) ? (
                      <ul className="list-disc ml-6 mb-4">
                        {section.content.map((item, idx) => (
                          <li className="font-light" key={idx}>{item}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className="font-light px-4 py-2">{section.content}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <a
              href="mailto:hr@nirantara.solutions"
              className="flex justify-center items-center gap-6 px-6 py-3 mt-6 max-w-full text-base text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-sm w-[148px] max-md:px-5"
            >
              <p>Apply</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="16"
                viewBox="0 0 37 16"
                fill="none"
              >
                <path
                  d="M36.7071 8.70711C37.0976 8.31659 37.0976 7.68342 36.7071 7.2929L30.3431 0.928935C29.9526 0.53841 29.3195 0.53841 28.9289 0.928935C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM-8.74228e-08 9L36 9L36 7L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      )}

    </article>
  );
}

function JobOpenings() {
  return (
    <main className="px-4 md:px-20 flex flex-col lg:gap-12 w-full" >
      <h1 className="text-black lg:text-5xl font-semibold uppercase helvetica " >
        JOB OPENINGS
      </h1>
      <div className="flex flex-col  ">
        <section className="justify-center w-full max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {jobData.slice(4, 6).map((job, index) => (
              <div
                key={index}
                className={`flex flex-col w-6/12 max-md:ml-0 max-md:w-full ${index === 1 ? "ml-5" : ""
                  }`}
              >
                <JobCard index={index} title={job.title} description={job.description} details={true} />
              </div>
            ))}
          </div>
        </section>
        <section className="justify-center mt-14 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {jobData.slice(2, 4).map((job, index) => (
              <div
                key={index}
                className={`flex flex-col w-6/12 max-md:ml-0 max-md:w-full ${index === 1 ? "ml-5" : ""
                  }`}
              >
                <JobCard title={job.title} />
              </div>
            ))}
          </div>
        </section>
        <section className="justify-center mt-14 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {jobData.slice(0, 2).map((job, index) => (
              <div
                key={index}
                className={`flex flex-col w-6/12 max-md:ml-0 max-md:w-full ${index === 1 ? "ml-5" : ""
                  }`}
              >
                <JobCard index={index} title={job.title} />
              </div>
            ))}
          </div>
        </section>
      </div>
    </main>

  );
}

export default JobOpenings;
