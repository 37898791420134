import React, { useEffect, useState } from 'react';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
function ExplainVedio() {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const videoElement = document.getElementById('videoPlayer');
      const rect = videoElement.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
      setIsFullScreen(isVisible);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const togglePlayPause = () => {
    const video = document.getElementById('videoPlayer');
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="relative w-full">
      <video
        id="videoPlayer"
        autoPlay
        loop
        muted
        playsInline
        className="w-full lg:h-screen object-cover "
      
      >
        <source src="/assets/videos/explain.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button
        className="absolute top-1/2 left-1/2 opacity-0 hover:opacity-100  transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-2 text-black"
        onClick={togglePlayPause}
      >
        {isPlaying ? <PauseCircleFilledIcon /> : <PlayCircleIcon />}
      </button>
    </div>
  );
}

export default ExplainVedio;
