function CollapseButton(props) {
  return (
    <div
      className=' cursor-pointer flex gap-5 justify-between self-center px-5 py-3 mt-8 text-base text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-sm'
      {...props}
    >
      {' '}
      <div>Collapse</div>{' '}
      <img
        loading='lazy'
        src='https://cdn.builder.io/api/v1/image/assets/TEMP/ae1b1960638ed58c96f037864861a90cda72abe31e7d205135bff45a9666237a?apiKey=b3217365a35d45c196e47ed20ac8b87c&'
        alt='Collapse icon'
        className='shrink-0 my-auto w-4 aspect-square'
      />{' '}
    </div>
  )
}


export default CollapseButton