import React from 'react'
import Landing from '../Carrer/Landing'
import PDFs from './PDFs'

function Certification() {
  return (
    <>
      <Landing />

      <h2 className='text-2xl ml-4 mt-10  p-4 font-semibold '>Quality Management System  <br />  ISO 9001:2015</h2>
      <div style={{ marginTop: '-20px' }}>
        <PDFs />
      </div>
    </>
  )
}

export default Certification
