import * as React from "react";

function Landing() {
  return (
    <div className="flex flex-col justify-center text-white w-full ">
      <div style={{height:`${window.innerWidth < 768 ? '200px' : null}`}} className="flex overflow-hidden relative flex-col items-center px-16 pt-6 pb-14 w-full h-96 min-h-[300px] md:min-h-[calc(100vh-400px)] lg:min-h-[calc(100vh-100px)] max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/eec8a56225c1aae2ba6eba19550c493752c5d15c18ade4d9150d28b15593d664?apiKey=b3217365a35d45c196e47ed20ac8b87c&"
          alt="Background"
          className="absolute inset-0 size-full"
        />
      </div>
    </div>
  );
}

export default Landing;
