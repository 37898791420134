import React, { useState } from "react";
import Landing from "./Landing";
import TechPlatform from "./TechPlatform";
import Deliverables from "./Deliverables";
import Training from "./Training";

const Products = () => {
  const [expanded, setExpanded] = useState(null)
  console.log(expanded)
  return (
    <div className="w-full md:px-12 md:py-16 py-4 px-4 flex flex-col items-center gap-20 mt-20 montserrat ">
      <Landing />
      <TechPlatform expanded={expanded} setExpanded={setExpanded} />
      <Deliverables expanded={expanded} setExpanded={setExpanded} />
      <Training expanded={expanded} setExpanded={setExpanded} />
    </div>
  );
};

export default Products;
