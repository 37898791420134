import * as React from "react";


function SustainabilityList({setIndex}) {
  const listItems = [
    {
      text: "SUSTAINABILITY",
      className:
        " max-md:max-w-full",
    },
    { text: "ESG", className: "mt-6 font-extrabold tracking-wider uppercase leading-[115%] max-md:max-w-full" },
    { text: "INTEGRATED APPROACH", className: "mt-7 max-md:max-w-full" },
    { text: "CLIMATE AND ESG", className: "mt-7 max-md:max-w-full" },
  ];

  return (
    <div className="flex flex-col grow shrink-0 text-sm text-white basis-0 w-fit max-md:max-w-full">
      {listItems.map((item, index) => (
        <div style={{cursor:'pointer'}} onClick={()=>setIndex(index)}  key={index} className={item.className}>
          {item.text}
        </div>
      ))}
     
    </div>
  );


}


const textItems = [
    { id: 1, content: "ESG" },
    { id: 2, content: "INTEGRATED APPROACH" },
    { id: 3, content: "CLIMATE AND ESG" },
  ];

function VerticalDivider() {
  return (
    <div className="flex gap-4">
    <div className="flex flex-col self-start">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/865684885fe70e8157bc1a8b2c82c2af261fda4f25128ded5e753fc60d3591a7?apiKey=8d89067347d641b99b038352d46b6e1d&"
        alt=""
        className="self-center border border-white border-solid aspect-[1.16] w-[7px]"
      />
      <div className="shrink-0 bg-white h-[15px]" />
      <div className="shrink-0 bg-white h-[15px]" />
      <div className="shrink-0 h-1.5 rounded-lg border border-white border-solid" />
      <div className="z-10 shrink-0 bg-white h-[15px]" />
      <div className="shrink-0 bg-white h-[15px]" />
      <div className="shrink-0 h-1.5 rounded-lg border border-white border-solid" />
      <div className="shrink-0 bg-white h-[15px]" />
      <div className="shrink-0 bg-white h-[15px]" />
      <div className="shrink-0 h-1.5 rounded-lg border border-white border-solid" />
    </div>
    <div className="flex flex-col px-5 text-sm text-white">
      <h2 className="font-extrabold tracking-wider uppercase leading-[115%]">
        Sustainability
      </h2>
      {textItems.map((item) => (
        <div key={item.id} className="mt-7">
          {item.content}
        </div>
      ))}
    </div>
  </div>
  );
}



function ESGLeft({setIndex}) {
  return (
    <div className="flex overflowhidden relative flex-col py-5 pr-5 pl-20 w-full h-full  max-md:pl-5">
      <img
        src="/assets/images/esg.jpeg"
        alt=""
        className="object-cover absolute inset-0 size-full"
      />
    
      <h1 className="relative  mt-32 text-6xl font-black tracking-wider text-white uppercase leading-[60.16px] maxmd:mt-10 max-md:max-w-full max-md:text-4xl">
    ESG
      </h1>
      <div className="flex relative gap-4  mt-36 -ml-2.5 max-md:flex-wrap max-md:mt-10">
        {/* <VerticalDivider /> */}
        <SustainabilityList setIndex={setIndex} />
      </div>
    </div>
  );
}

export default ESGLeft;
