import React from 'react'
import Landing from './Landing'
import Text from './Text'

const Resources = () => {
  return (
    <div className="w-full md:mb-20 mb-8 flex flex-col items-center gap-8  montserrat ">
        <Landing />
        <Text />
    </div>
  )
}

export default Resources