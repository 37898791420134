import * as React from "react";

function Landing() {

  const carrerURL ="https://cdn.builder.io/api/v1/image/assets/TEMP/46bdcfc66628a443be0b2a35071395eb131822eaed711155c49acbd8b82126d0?apiKey=b3217365a35d45c196e47ed20ac8b87c&";


  return (
    <div className="flex flex-col justify-center text-white w-full ">
      <div className={`flex overflow-hidden relative flex-col items-center px-16 pt-6 pb-14 w-full banner_container  lg:min-h-[calc(100vh-100px)] max-md:px-5 max-md:max-w-full`}>
        <img
          loading="lazy"

          src={ window.location.pathname.includes('career') ? carrerURL :  process.env.PUBLIC_URL + '/assets/images/certification.png'}
          alt="Background"
          className="object-cover absolute inset-0 size-full"
        />
       
      </div>
    </div>
  );
}

export default Landing;
