import * as React from "react";
import { useNavigate } from "react-router";

function DiscoverMoreButton() {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate('/integrated-approach')} className=" flex justify-center items-center gap-4  px-6 py-3 mt-8 max-w-full text-base text-center text-[#668D3D] bg-white w-[212px] max-md:px-5">
      <span className="">Discover More</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="16"
        viewBox="0 0 38 16"
        fill="none"
      >
        <path
          d="M37.2071 8.70711C37.5976 8.31659 37.5976 7.68342 37.2071 7.2929L30.8431 0.928935C30.4526 0.53841 29.8195 0.53841 29.4289 0.928935C29.0384 1.31946 29.0384 1.95262 29.4289 2.34315L35.0858 8L29.4289 13.6569C29.0384 14.0474 29.0384 14.6805 29.4289 15.0711C29.8195 15.4616 30.4526 15.4616 30.8431 15.0711L37.2071 8.70711ZM0.5 9L36.5 9L36.5 7L0.5 7L0.5 9Z"
          fill="#668D3D"
        />
      </svg>
    </button>
  );
}

function ApproachRight() {
  return (
    <section
      className="flex flex-col px-8  text-white w-full max-md:px-5 h-full "
      style={{
        background:
          " linear-gradient(36deg, #668D3D 36.93%, rgba(120, 176, 67, 0.57) 98.7%)",
      }}
    >
      <h1 className="mt-20 text-2xl font-black uppercase max-md:mt-10 max-md:max-w-full">
        Integrated Approach
      </h1>
      <p className=" text-xl leading-9 max-md:max-w-full hidden_scrollbar">
        At Nirantara, we focus on integrating Corporate Social Responsibility with ESG strategies on the
        guidelines of the United Nations Sustainable Development Goals (UN SDGs) to drive impactful
        change. By aligning our efforts with the UN SDGs, we aim not only to advance global objectives
        for a better world but also to guide our clients towards sustainable, inclusive growth. Together,
        let's transform challenges into opportunities for a sustainable future.
      </p>
      <DiscoverMoreButton />
    </section>
  );
}

export default ApproachRight;
