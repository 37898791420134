import * as React from "react";
function Process() {
  const internshipList = [
    "Comprehensive Exposure:Interns at Nirantara gain exposure to a wide range of sustainabilityprojects, from developing ESG reporting frameworks for clients to participating in sustainability assessments and implementing green initiatives.",
    "Mentorship and Learning: Each intern is paired with experienced mentors from our team of ESG consultants, offering personalized guidance and insights into the consulting profession and the burgeoning field of sustainability.",
    "Professional Development: The program is designed to equip interns with critical skills needed in the ESG space, including data analysis, stakeholder engagement, sustainability reporting, and strategic thinking.",
    "Collaborative Projects: Interns work collaboratively on projects, fostering teamwork and communication skills while contributing to meaningful sustainability outcomes for our clients.",
    "Diverse Industry Exposure: Nirantara serves a wide array of industries, allowing interns to understand the unique sustainability challenges and opportunities across different sectors.",
    "Career Pathways: The internship program serves as a stepping stone for a career in sustainability and ESG consulting, with high-performing interns often considered for full-time positions at Nirantara as well as placement in other reputed organisations."
  ]
  return (
    <section className=" px-4  md:px-20 flex flex-col text-5xl font-semibold text-black max-md:text-4xl">
      {" "}
      <h2 className="w-full uppercase max-md:max-w-full max-md:text-4xl">
        {" "}
        INTERNSHIP{" "}
      </h2>{" "}
      <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
        {" "}
        Nirantara Internship Program is designed to offer aspiring
        professionals a unique opportunity to immerse themselves in the world of
        Environmental, Social, and Governance (ESG) consulting. This program
        aims to bridge the gap between academic knowledge and real-world
        application, providing hands-on experience in sustainability initiatives
        and ESG integration strategies. Here's a brief overview of what the
        program offers: <br /> Program Highlights  
        <ul className="list-disc px-4 md:px-12">
        {internshipList.map(text=><li>{text}</li>)}
      </ul>
      </p>{" "}
      <h2 className="mt-16 w-full uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        {" "}
        IDEAL CANDIDATES{" "}
      </h2>{" "}
      <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
        {" "}
        We seek motivated who are passionate about sustainability and have a
        keen interest in ESG. Candidates should be currently enrolled in or
        recent graduates of relevant programs in environmental studies,
        sustainability, business, or related fields. Critical thinking,
        creativity, and a collaborative spirit are key attributes we look for.{" "}
      </p>{" "}
      <h2 className="mt-16 w-full uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        {" "}
        APPLICATION PROCESS{" "}
      </h2>{" "}
      <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
        {" "}
        The application process involves submitting a resume, a cover letter
        detailing your interest in sustainability and ESG, and any relevant
        coursework or projects. Selected candidates will be invited for an
        interview to discuss their interests and potential fit with Nirantara
        mission and values.{" "}
      </p>{" "}
      <h2 className="mt-16 w-full uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        {" "}
        SELECTION PROCESS{" "}
      </h2>{" "}
      <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
        {" "}
        Written examination <br /> Online Interview (first round) <br />{" "}
        In-Person Interview (second round) <br /> <br /> Making an Impact <br />{" "}
        The Nirantara Internship Program is more than just an internship; it's
        an opportunity to make a real impact. Interns leave the program not just
        with enhanced skills and knowledge, but with the satisfaction of having
        contributed to the global sustainability agenda. Join us to embark on a
        rewarding journey towards building a sustainable future. <br />{" "}
      </p>{" "}
    </section>
  );
}
export default Process;
