import * as React from "react";

const Card = ({ title, imageSrc, imageAlt, imageAspectRatio, imageWidth, redirectTo }) => {
  return (
    <div className="flex flex-col grow justify-center items-start p-8 w-full text-3xl font-semibold text-black uppercase bg-white rounded-lg shadow-lg leading-[50px] max-md:px-5 max-md:mt-5 max-md:max-w-full">
      <div>{title}</div>
      <a target="_blank" href={redirectTo} >
      <img
        src={imageSrc}
        alt={imageAlt}
        className={`mt-6 max-w-full aspect-[${imageAspectRatio}] w-2/3 `}
        />
      </a>
    </div>
  );
};

const Content = () => {
  const cardData = [
    {
      title: "World Trade Centers association",
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/b08d65ca7f7261fa01af4f3970cd3db3f7a2deba07a298dfe41ba69101e17ec9?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      imageAlt: "World Trade Centers association logo",
      imageAspectRatio: "5.26",
      imageWidth: "409px",
      isHalf: false,
      redirectTo:'https://www.wtca.org/'
    },
    {
      title: "IFRS SUSTAINABILITY ALLIANCE",
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/7c5d1bde0477e4c3614d59a89e4d9ad8e6acd8cd5d690f6db068199bc04bc108?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      imageAlt: "IFRS SUSTAINABILITY ALLIANCE logo",
      imageAspectRatio: "2.94",
      imageWidth: "226px",
      isHalf: true,
      redirectTo:'https://www.ifrs.org/products-and-services/sustainability-products-and-services/ifrs-sustainability-alliance/'
    },
    {
      title: "Mahratta Chamber of Commerce, Industries and Agriculture",
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/57a091a6fe27f5a0966e5c2166492dd113014fe9fc01b6cc95401f206b3e29eb?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      imageAlt: "Mahratta Chamber of Commerce, Industries and Agriculture logo",
      imageAspectRatio: "3.33",
      imageWidth: "247px",
      isHalf: true,
      redirectTo:'https://www.mcciapune.com/'
    },
  ];

  return (
    <main className="flex flex-col flex-wrap content-start montserrat max-w-7xl">
      <section className="w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-8 ">
            {cardData.map(card => <Card {...card} />)}
          </div>
         
        </div>
      </section>
    
    </main>
  );
};

export default Content;
