import { useEffect } from "react";
import * as React from "react";
import Sidebar from "./Sidebar";
import imgblack from '../Assets/Nirantara-logo-black.png';
import imgwhite from '../Assets/Nirantara-logo-white.png';
import { useNavigate, useLocation } from "react-router";

function Header({ visible }) {
  const [open, setOpen] = React.useState(false);
  const [scroll, SetScroll] = React.useState(true);
  const [scrollProduct, SetScrollProduct] = React.useState(true);
  const navigate = useNavigate();
  const path = useLocation().pathname;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setOpen(false);
  }, [path]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 150) {
        SetScroll(true);
        SetScrollProduct(false);
      } else {
        SetScroll(false);
        SetScrollProduct(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const goHome = () => {
    if (window.location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div>
        <Sidebar open={open} onClose={() => setOpen(false)} />
        <header
          style={{ position: "fixed", top: 0, opacity: visible ? 1 : 0 }}
          className={`helvetica sticky top-[-4rem] z-10 py-2 w-full bg-${scroll ? 'transparent' : 'white'} flex gap-5 justify-between md:px-10 px-4 ${path === '/products' || path === '/contact-us' ? "text-black" : "text-white"} whitespace-nowrap`}
        >
          <div
            className="flex gap-0 justify-center items-center px-5 text-3xl tracking-tighter capitalize cursor-pointer"
            onClick={() => goHome()}
          >
            <img
              loading="lazy"
              src={path !== '/products' && scroll ? imgwhite : imgblack}
              alt="Nirantara logo"
              className="shrink-0 aspect-[1.15] cursor-pointer h-[38px] w-[140px]"
            />
          </div>
          <div
            onClick={() => setOpen(true)}
            className="flex cursor-pointer flex-row gap-3 items-center py-px my-auto text-base font-semibold leading-5 text-right"
          >
            <div
              style={{
                color: `${path !== '/products' && scroll ? 'white' : 'black'}`,
                fontSize: '14px',
                fontWeight: '600'
              }}
              className={`text-${path === "/products" ? 'black' : 'white'}`}
            >
              MENU
            </div>
            <div className="h-5 flex flex-col justify-between">
              <div
                style={{ background: `${path !== '/products' && scroll ? 'white' : 'black'}` }}
                className={`shrink-0 h-0.5 ${path === '/products' ? "bg-black" : "bg-white"} shadow-sm w-[18px]`}
              />
              <div
                style={{ background: `${path !== '/products' && scroll ? 'white' : 'black'}` }}
                className={`shrink-0 h-0.5 ${path === '/products' ? "bg-black" : "bg-white"} shadow-sm w-[11px]`}
              />
              <div
                style={{ background: `${path !== '/products' && scroll ? 'white' : 'black'}` }}
                className={`shrink-0 h-0.5 ${path === '/products' ? "bg-black" : "bg-white"} shadow-sm w-[26px]`}
              />
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default Header;
