import * as React from "react";

function EmployeeEngagement() {
  return (
    <li className="text-left" >
      <span className="font-semibold text-black">Employee Engagement</span>:
      Customized workshops and training sessions for specific topics as well as
      to embed a culture of sustainability and social responsibility across all
      levels of your organization.
    </li>
  );
}

function LeadershipAdvisory() {
  return (
    <li>
      <span className="font-semibold text-black">Leadership Advisory</span>:
      Strategic advice to leadership teams on integrating ESG considerations
      into corporate governance and-making processes
    </li>
  );
}

function VendorContractorAlignment() {
  return (
    <li>
      <span className="font-semibold text-black">
        Vendor and contractor alignment workshops
      </span>
      : Offering workshops, seminars, and training sessions on ESG topics to
      vendors, suppliers and contractors.
    </li>
  );
}

function ESGMonitoringReporting() {
  return (
    <li>
      <span className="font-semibold text-black">
        Boosting ESG Monitoring and Reporting Abilities:
      </span>{" "}
      Building organisational capacity to measure, manage and report on ESG
      issues.
    </li>
  );
}

function CorporateTrainingPrograms() {
  return (
    <section className="flex flex-col px-7 w-full max-md:px-5 max-md:max-w-full">
      <h2 className="self-center text-5xl font-semibold text-black uppercase max-md:max-w-full max-md:text-4xl">
        CORPORATE TRAINING PROGRAMS
      </h2>
      <div className="mt-16 text-left flex flex-col gap-4 items-start text-lg leading-9 text-black max-md:mt-10 max-md:max-w-full">
        <span className="text-xl font-semibold text-black text-left">
          Training and Capacity Building
        </span>
        <ul className=" md:px-6 px-2 list-disc marker:text-black">
          <EmployeeEngagement />
          <LeadershipAdvisory />
          <VendorContractorAlignment />
          <ESGMonitoringReporting />
        </ul>
      </div>
    </section>
  );
}

function AcademicCollaborations() {
  return (
    <li>
      <span className="font-semibold text-black">
        Collaborations with Academic institutions for
      </span>
      <ul>
        <li>
          Practical integrating ESG topics across disciplines, not just in
          environmental studies or business courses but also in engineering,
          law, and social sciences, to provide a holistic understanding of
          sustainability challenges.
        </li>
        <li>
          Research and Innovation: Exploring topics like climate change
          mitigation, social inequality, corporate governance, and sustainable
          finance.
        </li>
        <li>
          Social Projects: Bring academic expertise to solve real-world problems
          and fostering innovation in sustainability practices
        </li>
      </ul>
    </li>
  );
}

function InstitutionalGovernance() {
  return (
    <li>
      <span className="font-semibold text-black">
        Institutional Governance and Operations
      </span>
      <ul>
        <li>
          Sustainable Campus Initiatives: Collaborations with Academic
          Institutions for implementing ESG principles in their own operations,
          from reducing carbon footprints and enhancing energy efficiency to
          promoting diversity and inclusion within their communities.
        </li>
      </ul>
    </li>
  );
}

function PublicEngagement() {
  return (
    <li>
      <span className="font-semibold text-black">Public Engagement</span>
      <ul>
        <li>
          Academia plays a crucial role in raising public awareness about ESG
          issues, hosting lectures, seminars, and conferences that are open to
          the broader community.
        </li>
      </ul>
    </li>
  );
}

function StudentLedInitiatives() {
  return (
    <li>
      <span className="font-semibold text-black">
        Student-Led Initiatives
      </span>
      <ul>
        <li>
          Collaborating ESG initiatives on campuses promoting student
          leadership, reflecting the increasing demand for sustainability in
          education and action among the younger generation.
        </li>
      </ul>
    </li>
  );
}

function IndustryReadinessPathways() {
  return (
    <li>
      <span className="font-semibold text-black">
        Industry readiness pathways
      </span>
      <ul>
        <li>
          Integrating practical ESG related activities into education for
          enhanced skill development creating industry ready professionals
          focused on sustainability roles.
        </li>
      </ul>
    </li>
  );
}

function Academia() {
  return (
    <> 
      <h2 className="self-center mt-16 text-5xl font-semibold text-black uppercase max-md:mt-10 max-md:text-4xl">
        ACADEMIA
      </h2>
      <div className="mt-16 text-left flex flex-col gap-4 items-start text-lg leading-9 text-black max-md:mt-10 max-md:max-w-full">
        <span className="font-bold leading-6 text-black">
          Education and Curriculum Development
        </span>
        <ul className=" md:px-6 px-2 list-disc marker:text-black">
          <AcademicCollaborations />
          <InstitutionalGovernance />
          <PublicEngagement />
          <StudentLedInitiatives />
          <IndustryReadinessPathways />
        </ul>
      </div>
    </>
  );
}

function TrainingExpansion() {
  return (
    <div className="flex flex-col md:mt-12 mt-4 md:px-12 px-4 ">
      <CorporateTrainingPrograms />
      <Academia />
    </div>
  );
}

export default TrainingExpansion;