import logo from "./logo.svg";
import "./App.css";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Landing from "./components/Home/Landing";
import Products from "./components/Products/Products";
import Journey from "./components/Journey/Journey";
import Carrer from "./components/Carrer/Carrer";
import Team from "./components/Team/Team";
import Resources from "./components/Resources/Resources";
import Sustainability from "./components/Sustainability/Sustainability";
import Esg from "./components/Esg/Esg";
import Approach from "./components/IntegratedApproach/Approach";
import Climate from "./components/Climate/Climate";
import PartnerShip from "./components/PartnerShip/PartnerShip";
import Contact from "./components/Contact/Contact";
import {useState,useRef, useEffect } from "react";
import scrollToSentence from "./components/scrolltoPosition";
import Certification from "./components/certification/Certification";

function highlightWord(word) {
  function traverseAndHighlight(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.nodeValue;
      const regex = new RegExp(`\\b(${word})\\b`, 'gi');
      if (regex.test(text)) {
        const highlightedText = text.replace(regex, '<span class="highlight">$1</span>');
        const span = document.createElement('span');
        span.innerHTML = highlightedText;
        node.parentNode.replaceChild(span, node);
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      node.childNodes.forEach(traverseAndHighlight);
    }
  }

  traverseAndHighlight(document.body);
}


function App() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchVal = searchParams.get("search") || "";
  const[visible,setVisible]=useState(true);
useEffect(()=>{
 if(searchVal){
  highlightWord(searchVal)
  scrollToSentence(searchVal)
 }

 
},[searchVal])
const prevScrollY = useRef(0);
const bodyRef = useRef(null); 
useEffect(() => {
  if (bodyRef.current) {
    const bodyBackgroundColor = window.getComputedStyle(bodyRef.current).backgroundColor;
    console.log('Body background color:', bodyBackgroundColor);
  }
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    
    // Determine scroll direction
    if (currentScrollY < prevScrollY.current) {
      // getBodyBackgroundColor();
      setVisible(true);
      // Perform actions for scrolling up
    } else {
      // getBodyBackgroundColor();
      setVisible(false);
      // Perform actions for scrolling down or no change
    }
    
    // Update prevScrollY with current scroll position
    prevScrollY.current = currentScrollY;
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);




  return (
    <div className={`w-full flex  flex-col  ${window.location.pathname === "/" &&  'h-screen  bg-neutral-800'}`}>
      <Header visible={visible} />
      <div style={{marginTop:'-5rem'}}>
      <Routes>
        <Route path="*" element={<Landing />} />
        <Route path="/" element={<Landing />} />
        <Route path="/products" element={<Products />} />
        <Route path="/journey" element={<Journey />} />
        <Route path="/career" element={<Carrer />} />
        <Route path="/our-team" element={<Team />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/certification" element={<Certification />} />
        <Route path="/sustainability" element={<Sustainability />} />
        <Route path="/esg" element={<Esg />} />
        <Route path="/integrated-approach" element={<Approach />} />
        <Route path="/climate-and-esg" element={<Climate />} />
        <Route path="/membership-and-partnership" element={<PartnerShip />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
