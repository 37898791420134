import React from "react";
import Landing from "./Landing";
import JobOpenings from "./JobOpenings";
import Process from "./Process";

const Carrer = () => {
  return (
    <div className="w-full md:mb-20 mb-8 flex flex-col items-center gap-8  montserrat ">
      <Landing />
      <JobOpenings />
      <Process />
    </div>
  );
};

export default Carrer;
