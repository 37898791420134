import * as React from "react";
import { useNavigate } from "react-router";

function DiscoverMoreButton() {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate('/climate-and-esg')} className=" flex justify-center items-center gap-4  px-6 py-3 mt-8 max-w-full text-base text-center text-[#925123] bg-white w-[212px] max-md:px-5">
      <span className="">Discover More</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="16" viewBox="0 0 38 16" fill="none">
        <path d="M37.2071 8.70711C37.5976 8.31659 37.5976 7.68342 37.2071 7.2929L30.8431 0.928935C30.4526 0.53841 29.8195 0.53841 29.4289 0.928935C29.0384 1.31946 29.0384 1.95262 29.4289 2.34315L35.0858 8L29.4289 13.6569C29.0384 14.0474 29.0384 14.6805 29.4289 15.0711C29.8195 15.4616 30.4526 15.4616 30.8431 15.0711L37.2071 8.70711ZM0.5 9L36.5 9L36.5 7L0.5 7L0.5 9Z" fill="#925123" />
      </svg>
    </button>
  );
}

function ClimateRight() {
  return (
    <section
      className="flex flex-col px-8  text-white w-full max-md:px-5 h-full "
      style={{
        background:
          " linear-gradient(36deg, #915022 36.93%, #F3B677 98.7%)",
      }}
    >
      <h1 className="mt-12 text-2xl font-black uppercase max-md:mt-10 max-md:max-w-full">
        Climate and ESG
      </h1>
      <p className="mt-7 text-xl leading-9 h-1/2 max-md:max-w-full overflow-scroll hidden_scrollbar">
        At Nirantara, we bridge the gap between climate action and business profitability, proving that
        sustainability and success go hand in hand. Our services and deliverables are designed to
        empower businesses to embrace environmental stewardship as a cornerstone of their growth
        strategy. By integrating Environmental, Social, and Governance (ESG) principles, we help clients
        innovate, reduce costs, and mitigate risks, transforming climate challenges into profitable
        opportunities. Partner with us to elevate your sustainability performance and drive competitive
        advantage in a green economy.
      </p>
      <DiscoverMoreButton />
    </section>
  );
}

export default ClimateRight;
