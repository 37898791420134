import React from "react";
import Landing from "./Landing";
import Content from "./Content";


const PartnerShip = () => {
  return (
    <div className="w-full md:mb-20 mb-8 flex flex-col items-center gap-8  helvetica ">
      <Landing />
      <Content />
    </div>
  );
};

export default PartnerShip;
