import * as React from "react";

function Text() {
  return (
    <article className="text-lg leading-9 text-black max-w-7xl flex flex-col gap-4 md:gap-8 px-4">
      <p>
        At Nirantara, we believe that addressing climate change is not just an environmental imperative but a strategic business opportunity. Our dedicated approach to Climate Action, embedded within our comprehensive Environmental, Social, and Governance (ESG) implementation services, reflects our commitment to guiding businesses towards sustainable and responsible growth. We strive to be pioneers in implementation of sustainable business practices while carefully navigating the critical intersection between ESG and Climate Action.
      </p>
      <p>
        Our services are designed to allow businesses to focus on market competitiveness throughout while seamlessly integrating the elements of ESG and sustainability into routine processes and creating future proofing measures simultaneously.
      </p>
      <p>
        Adopting Climate Action as a central component of your ESG strategy opens up numerous opportunities for innovation and growth. Sustainable practices can lead to cost savings through improved efficiency while creating positive value through enhancement of brand equity and customer loyalty. Moreover, businesses that proactively address climate risks are better positioned to navigate regulatory changes, attract ESG-focused investments, and secure a competitive advantage in the transition to a low-carbon economy.
      </p>
      <p>
        At Nirantara, our team is committed to providing tailored ESG consultancy services that empower your business to lead with sustainability at the forefront. By partnering with us, you gain access to cutting-edge insights, strategies, and tools for integrating Climate Action into your business operations and corporate ethos.
      </p>
      <p>
        Join us in transforming challenges into opportunities for sustainable growth. Together, we can pave the way for a more resilient business strategy and a prosperous future.
      </p>
    </article>
  );
}

export default Text;