import * as React from "react";
import { useNavigate } from "react-router";

const Title = ({ children }) => (
  <h2 className="text-5xl font-semibold text-black uppercase max-md:text-4xl mt-8">
    {children}
  </h2>
);

const Paragraph = ({ children }) => (
  <p className="mt-6 text-lg leading-9 text-black max-md:max-w-full ">
    {children}
  </p>
);

const ListItem = ({ children }) => (
  <li className="text-black" >
    <span className="font-semibold leading-6 text-black">{children[0]}</span>{" "}
    {children[1]}
  </li>
);

function Text() {
  const navigate = useNavigate()
  return (
    // <div className="flex flex-col max-md:text-4xl max-w-7xl gap-8 px-4 ">
    <div className=" px-4  md:px-20 flex flex-col text-5xl  text-black max-md:text-4xl">
      <header>
        <Title>Champions of Sustainable Progress</Title>
      </header>
      <main className="flex flex-col gap-2" >
        <Paragraph>
          At Nirantara, our team is our greatest asset and the driving force
          behind our mission to integrate Environmental, Social, and Governance
          (ESG) principles into the core of business operations worldwide.
          Composed of a diverse group of seasoned professionals, innovators, and
          thought leaders with expertise diverse industries including buit not
          limited to sustainability, finance, trading, manufacturing, logistics,
          shipping, consultancy, corporate strategy, and environmental science,
          the Nirantara team is united by a common goal: to create a more
          sustainable and equitable future for all.
        </Paragraph>
        
        
        <section className="mb-8 mt-4 ">
          <Title>CORE STRENGTHS</Title>
          <div className="mt-6 text-lg leading-9 text-black max-md:mr-2 max-md:max-w-full">
            <ul className="px-8 list-disc">
              <ListItem>
                {[
                  "Multidisciplinary Expertise:",
                  "Our team brings together specialists from various fields, including environmental management, social science, corporate governance, data analytics, and sustainable finance. This multidisciplinary approach enables us to offer comprehensive ESG solutions tailored to the unique needs of each client.",
                ]}
              </ListItem>
              <ListItem>
                {[
                  "Qualified and certified professionals:",
                  "From the leadership downwards, the entire team are Certified for ISO 9001:2015 (QMS), ISO 14001:2015 (EMS), ISO 45001:2018 (OH&S), ISO 50001:2018 (ENMS), ISO 22000:2018 (FSMS), ISO/IEC 27001:2022 (ISMS); ISO/IEC 20000-1:2018 (ITSM), ISO 22301:2019 (BCMS), ISO 29001:2020 (Petroleum, Petrochemical and Natural Gas Industries), ISO 37001:2016 (Anti-bribery), ISO 28000:2022 (Security Management), ISO 15378:2017 (Packaging QMS), ISO 21001:2018 (EOMS) and SA 8000 (Social Accountability).",
                ]}
              </ListItem>
              <ListItem>
                {[
                  "Passion for Sustainability:",
                  "Each member of our team is deeply committed to sustainability, not just as a profession but as a personal ethos. This passion drives our relentless pursuit of innovative solutions that can make a real difference in the world.",
                ]}
              </ListItem>
              <ListItem>
                {[
                  "Collaborative Spirit:",
                  "We believe in the power of collaboration, both within our team and with our clients, partners, and the broader community. Our collaborative culture fosters open communication, mutual respect, and a shared vision for achieving sustainable outcomes.",
                ]}
              </ListItem>
              <ListItem>
                {[
                  "Experienced Consultants:",
                  "The consultants at Nirantara are seasoned professionals with extensive experience in ESG consulting, corporate strategy, and sustainability reporting. They have guided numerous businesses through the complexities of ESG integration, leveraging best practices and innovative strategies to achieve sustainable success.",
                ]}
              </ListItem>
              <ListItem>
                {[
                  "Dedicated Researchers:",
                  "Supporting our consultants, our dedicated research team stays abreast of the latest developments in sustainability, ESG regulations, and global trends. Their in-depth analyses and insights inform our consulting strategies, ensuring our advice is grounded in current data and forward-thinking perspectives.",
                ]}
              </ListItem>
              <ListItem>
                {[
                  "Innovative Technologists:",
                  "Our technology team specializes in developing and implementing cutting-edge solutions for ESG data management, analysis, and reporting. By harnessing the power of AI, blockchain, and IoT, they enable our clients to monitor their sustainability performance with precision and ease.",
                ]}
              </ListItem>
              <ListItem>
                {[
                  "Engaged Project Managers:",
                  "Nirantara project managers excel in orchestrating complex projects, ensuring seamless coordination between our team and our clients. Their meticulous attention to detail and commitment to timelines guarantee that every project is delivered with excellence and achieves its intended impact.",
                ]}
              </ListItem>
              <ListItem>
                {[
                  "Collaborative Culture:",
                  "At the heart of Nirantara is a collaborative culture that values open communication, teamwork, and continuous learning. We believe that by working together, sharing knowledge, and fostering innovation, we can overcome sustainability challenges and make a lasting impact on the world.",
                ]}
              </ListItem>
            </ul>
            <br />
            <span className="text-black">
              The Nirantara team is not just a group of individuals; it's a
              dynamic community of professionals committed to making
              sustainability achievable for every business. Together, we are
              shaping a more sustainable, equitable, and prosperous future for
              all.
            </span>
          </div>
        </section>
        <section className="mb-8">
          <Title>OUR LEADERSHIP</Title>
          <Paragraph>
            Our leadership team comprises seasoned professionals with extensive
            experience in sustainability consulting and a proven track record of
            guiding companies through their ESG journeys. They are not only
            strategic thinkers but also committed visionaries with hands-on
            leadership experience and who are actively involved in project work,
            ensuring that our high standards of quality and impact are
            consistently met.
          </Paragraph>
        </section>
        <section className="mb-8">
          <Title>Our Commitment to Continuous Learning</Title>
          <Paragraph>
            In the fast-evolving field of sustainability, continuous learning is
            essential. We invest in ongoing professional development for our
            team, keeping abreast of the latest trends, regulations, and best
            practices in ESG and sustainability. This commitment to learning
            ensures that we remain at the forefront of the industry, equipped to
            offer our clients the most current and effective strategies.
            <br />
            <br />
            The Nirantara team is more than just a group of professionals; we
            are a community of sustainability visionaries dedicated to driving
            change. Together, we are working towards a common mission of a
            sustainable business landscape that not only thrives economically
            but also contributes positively to society and the environment.
          </Paragraph>
        </section>
        <section className="mb-8">
          <Title>JOIN OUR TEAM</Title>
          <Paragraph>
            We look for talented individuals who share our commitment to
            sustainability and desire to make a positive impact. If you are
            passionate about ESG and looking for an opportunity to grow with a
            dynamic and supportive team, Nirantara might be the place for you.
            For more details, visit our <a onClick={()=> navigate('/career')} className=" underline font-semibold cursor-pointer">Careers Page</a>
          </Paragraph>
        </section>
      </main>
    </div>
  );
}

export default Text